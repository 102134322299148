import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store/index";
import { headings } from "./../helper/mapper";

const Budget = () => import(/* webpackChunkName: "budget" */ '@/views/budget/budget.vue');
const Poi = () => import(/* webpackChunkName: "poi" */ '@/views/pointOfInterest/PointOfInterest.vue');
const CitizenCharter = () => import(/* webpackChunkName: "citizen-charter" */ '@/views/citizenCharter/citizenCharter.vue');
const CitizenCharterDetail = () => import(/* webpackChunkName: "citizen-charter-detail" */ '@/views/citizenCharter/citizenCharterDetail.vue');
const Policy = () => import(/* webpackChunkName: "policy" */ '@/views/policy/policy.vue');
const Decision = () => import(/* webpackChunkName: "decision" */ '@/views/decision/decision.vue');
const About = () => import(/* webpackChunkName: "about" */ '@/views/about/about.vue');
const Ims = () => import(/* webpackChunkName: "ims" */ '@/views/ims/ims.vue');
const Achievements = () => import('@/views/about/achievement/achievements.vue');
const AchievementDetail = () => import('@/views/about/achievement/achievementDetail.vue');
const UpcomingPrograms = () => import('@/views/about/upcomingProgram/upcomingPrograms.vue');
const UpcomingProgramDetail = () => import('@/views/about/upcomingProgram/upcomingProgramDetail.vue');
const History = () => import('@/views/about/history.vue');
const Introduction = () => import('@/views/about/introduction.vue');
const Naming = () => import('@/views/about/naming.vue');
const ProgramList = () => import('@/views/budget/programList.vue');
const ProgramDetail = () => import('@/views/budget/programDetail.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: "/budget",
    name: "Budget",
    component: Budget,
    meta: {
      showFilter: true
    }
  },
  {
    path: "/program-list/:programName",
    name: "Program-list",
    component: ProgramList,
    meta: {
      showFilter: true
    }
  },
  {
    path: "/program-list/:programName/:programId",
    name: "Program-detail",
    component: ProgramDetail,
    meta: {
      showFilter: false
    }
  },
  {
    path: '/point-of-interest',
    name: 'Poi',
    component: Poi,
    meta: {
      showFilter: false
    }
  },
  {
    path: '/citizen-charter',
    name: 'Citizen-charter',
    component: CitizenCharter,
    meta: {
      showFilter: true
    }
  },
  {
    path: '/citizen-charter/:id',
    name: 'Citizen-charter-detail',
    component: CitizenCharterDetail,
    meta: {
      showFilter: false
    }
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
    meta: {
      showFilter: true
    }
  },
  {
    path: "/decisions",
    name: "Decisions",
    component: Decision,
    meta: {
      showFilter: true
    }
  },
  {
    path: "/",
    component: About,
    meta: {
      showFilter: true
    },
    children: [
      {
        path: '/',
        name: 'achievements',
        component: Achievements
      },
      {
        path: '/achievement-detail/:achievementId',
        name: 'achievement-detail',
        component: AchievementDetail
      },
      {
        path: '/upcoming-programs',
        name: 'upcoming-programs',
        component: UpcomingPrograms
      },
      {
        path: '/upcoming-program-detail/:upcomingProgramId',
        name: 'upcoming-program-detail',
        component: UpcomingProgramDetail
      },
      {
        path: '/history',
        name: 'history',
        component: History
      },
      {
        path: '/introduction',
        name: 'introduction',
        component: Introduction
      },
      {
        path: '/naming',
        name: 'naming',
        component: Naming
      }

    ]
  },
  {
    path: "/ims",
    name: "Ims",
    component: Ims,
    meta: {
      showFilter: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  let name = to.name;
  //dynamically set name in heading
  if (headings[name]) {
    store.dispatch("setData", {
      mutation: "SET_HEADING",
      data: headings[name]
    });
  }

  next();
});

export default router;
