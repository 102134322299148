export const poiTypes = [
  {
    text: "विद्यालय वा शैक्षिक संस्था",
    value: "edu_institute"
  },
  {
    text: "गाउँ कार्यपालिका कार्यालय वा वडा कार्यालय वा अन्य गाउँपालिका संरचना",
    value: "muni_or_ward"
  },
  {
    text: "अस्पताल/स्वास्थ्य केन्द्र/अन्य स्वास्थ्य संस्था",
    value: "health_institu"
  },
  {
    text: "सडक",
    value: "road"
  },
  {
    text: "कृषि स्थल",
    value: "agri_institute"
  },
  {
    text: "पशुपालन",
    value: "animal_husband"
  },
  {
    text: "अन्य",
    value: "others"
  }
];

export const headings = {
  Budget: "वार्षिक विकास कार्यक्रम",
  "Program-list": "वार्षिक विकास कार्यक्रम",
  "Program-detail": "वार्षिक विकास कार्यक्रम",
  Poi: "आकर्षणका केन्द्रकाे प्रकार नक्शा",
  "Citizen-charter": "नागरिक वडापत्र",
  "Citizen-charter-detail": "नागरिक वडापत्र",
  Policy: "महत्वपूर्ण नीतिहरु",
  Decisions: "गाउँसभाका निर्णयहरु",
  Ims: "आयाेजनाहरूको नक्सामा आधारित वितरण",
  achievements: "महत्वपुर्ण कार्यक्रम",
  "achievements-detail": "महत्वपुर्ण कार्यक्रम",
  "upcoming-programs": "आगामी कार्यक्रम",
  "upcoming-program-detail": "आगामी कार्यक्रम",
  introduction: "परिचय",
  history: "इतिहास",
  naming: "नामकरण"
};

export const mellekPoi = {
  edu_institute: {},
  muni_or_ward: {},
  health_institu: {
    health_services: {
      type: "array_string",
      label: "स्वास्थ्य संस्थामा हाल उपलब्ध सेवाहरु",
      values: {
        specialist: " बिशेषज्ञद्वारा बिरामी जाँच",
        pharmacy: "फार्मेसी सेवा",
        pathology: "प्याथाेलाेजी सेवा",
        orthopedic: "हाडजाेर्नी उपचार",
        maternity: "सुत्केरी उपचार",
        immunization: "खाेप सेवा",
        emergency: "ईमर्जेन्सी सेवा",
        ambulance: "एम्बुलेन्स सेवा",
        others: "अन्य"
      }
    }
  },
  road: {},
  agri_institute: {},
  animal_husband: {},
  others: {}
};

export const poiMap = {
  poiType1: {
    "begin_group_122e5e60/tourist_fee": {
      type: "conversion",
      label: "प्रवेश शुल्क"
    },
    "begin_group_122e5e60/tourist_num": {
      type: "conversion",
      label: "दैनिक औसतमा आउने पर्यटकहरुको संख्या"
    },
    "begin_group_122e5e60/tourist_imp": {
      type: "text",
      label: "स्थलको महत्व"
    }
  },
  poiType2: {
    "begin_group_f136067b/category": {
      type: "list",
      label: "शिक्षण संस्थाकाे वर्ग",
      children: [
        {
          name: "1",
          label: "सरकारी"
        },
        {
          name: "2",
          label: "सामुदायिक"
        },
        {
          name: "3",
          label: "नीजि"
        },
        {
          name: "_",
          label: "अन्य"
        }
      ]
    },
    "begin_group_f136067b/school_type": {
      type: "list",
      label: "शिक्षण संस्थाकाे प्रकार",
      children: [
        {
          name: "1",
          label: "प्रावि"
        },
        {
          name: "2",
          label: "निमावि"
        },
        {
          name: "3",
          label: "मावि"
        },
        {
          name: "4",
          label: "उमावि"
        },
        {
          name: "5",
          label: "क्याम्पस"
        },
        {
          name: "6",
          label: "विश्वविद्यालय"
        },
        {
          name: "7",
          label: "वेद विद्याश्रम"
        },
        {
          name: "8",
          label: "मदरसा"
        },
        {
          name: "9",
          label: "प्राविधिक शिक्षालय"
        },
        {
          name: "10",
          label: "सीप विकास तालिम केन्द्र"
        },
        {
          name: "11",
          label: "सामुदायिक बाल विकास केन्द्र"
        },
        {
          name: "other",
          label: "अन्य"
        }
      ]
    },
    "begin_group_f136067b/other_type": {
      type: "text",
      label: "अन्य प्रकार भए खुलाउनुहोस"
    },
    "begin_group_f136067b/total_students": {
      type: "conversion",
      label: "कूल विद्यार्थी संख्या"
    },
    "begin_group_f136067b/total_male": {
      type: "conversion",
      label: "छात्रकाे संख्या"
    },
    "begin_group_f136067b/total_female": {
      type: "conversion",
      label: "छात्राकाे संख्या"
    },
    "begin_group_f136067b/total_teachers": {
      type: "conversion",
      label: "कूल शिक्षक संख्या"
    },
    "begin_group_f136067b/total_malet": {
      type: "conversion",
      label: "पुरुष शिक्षक संख्या"
    },
    "begin_group_f136067b/total_femalet": {
      type: "conversion",
      label: "महिला शिक्षक संख्या"
    },
    "begin_group_f136067b/total_classrooms": {
      type: "conversion",
      label: "कक्षाकाेठाकाे संख्या"
    },
    "begin_group_f136067b/water": {
      type: "list",
      label: "स्वच्छ पिउने पानीकाे व्यवस्था छ छैन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_f136067b/toilet": {
      type: "list",
      label: "शाैचालयकाे व्यवस्था छ छैन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_f136067b/library": {
      type: "list",
      label: "पुस्तकालयकाे व्यवस्था छ छैन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_f136067b/playground": {
      type: "list",
      label: "खेलमैदानकाे व्यवस्था छ छैन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_f136067b/canteen": {
      type: "list",
      label: "चमेनागृहकाे व्यवस्था छ छैन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_f136067b/internet": {
      type: "list",
      label: "इन्टरनेटकाे व्यवस्था छ छैन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_f136067b/disability_friendly": {
      type: "list",
      label: "अपांगमैत्री संरचना हाे हाेइन ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    }
  },
  poiType3: {
    "begin_group_e89fa6db/room_no": {
      type: "conversion",
      label: "कोठा संख्या"
    },
    "begin_group_e89fa6db/building_ownership": {
      type: "list",
      label: "भवन अाफ्नाे वा भाडामा",
      children: [
        {
          name: "1",
          label: "आफ्नै"
        },
        {
          name: "2",
          label: "भाडामा"
        },
        {
          name: "3",
          label: "अन्य"
        }
      ]
    },
    "begin_group_e89fa6db/mun_building_type": {
      type: "list",
      label: "भवनको प्रकृति",
      children: [
        {
          name: "1",
          label: "गोटा"
        },
        {
          name: "2",
          label: "कच्ची"
        },
        {
          name: "3",
          label: "पक्की"
        }
      ]
    },
    "begin_group_e89fa6db/Internet": {
      type: "list",
      label: "इन्टरनेटकाे व्यवस्था",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_e89fa6db/disability_friendly_001": {
      type: "list",
      label: "अपांगमैत्री संरचना",
      children: [
        {
          name: "1",
          label: "हो"
        },
        {
          name: "2",
          label: "होईन"
        }
      ]
    }
  },
  poiType4: {
    "begin_group_139cf2d5/main_trading_objects": {
      type: "text",
      label: "मुख्य व्यापारिक वस्तुहरु"
    },
    "begin_group_139cf2d5/if_any_other": {
      type: "text",
      label: "अन्य विवरण केही भए"
    }
  },
  poiType5: {
    "begin_group_ab3f395e/health_type": {
      type: "list",
      label: "स्वास्थ्य सेवाको प्रकार",
      children: [
        {
          name: "1",
          label: "जिल्ला जनस्वास्थ्य कार्यालय"
        },
        {
          name: "2",
          label: "सरकारी अस्पताल"
        },
        {
          name: "3",
          label: "प्रसुती गृह"
        },
        {
          name: "4",
          label: "बाल अस्पताल"
        },
        {
          name: "5",
          label: "आयुर्बेद अस्पताल"
        },
        {
          name: "6",
          label: "प्राकृतिक चिकित्सा"
        },
        {
          name: "7",
          label: "स्वास्थ्य चौकी"
        },
        {
          name: "8",
          label: "उप–स्वास्थ्य चौकी"
        },
        {
          name: "9",
          label: "प्राथमिक स्वास्थ्य स्याहार केन्द्र"
        },
        {
          name: "10",
          label: "निजि अस्पताल"
        },
        {
          name: "11",
          label: "फार्मेसी"
        },
        {
          name: "12",
          label: "क्लिनिक"
        },
        {
          name: "13",
          label: "बर्थिंग् सेन्टर"
        },
        {
          name: "14",
          label: "पोलि क्लिनिक"
        }
      ]
    },
    "begin_group_ab3f395e/health_bed_num": {
      type: "conversion",
      label: "बेड संख्या"
    },
    "begin_group_ab3f395e/health_staff": {
      type: "conversion",
      label: "कूल स्वास्थ्यकर्मीहरुकाे संख्या"
    },
    "begin_group_ab3f395e/health_doctors": {
      type: "conversion",
      label: "चिकित्सक संख्या"
    },
    "begin_group_ab3f395e/pharmacy": {
      type: "list",
      label: "अस्पताल हाे भने आफ्नै आैषधी पसल छ ?",
      children: [
        {
          name: "1",
          label: "छ"
        },
        {
          name: "2",
          label: "छैन"
        }
      ]
    },
    "begin_group_ab3f395e/other_001": {
      type: "text",
      label: "उपलब्ध सेवाहरु अन्य भए खुलाउनुहेस्"
    }
  },
  poiType6: {
    "begin_group_9275d410/fin_type": {
      type: "list",
      label: "किसिम",
      children: [
        {
          name: "1",
          label: "सहकारी संस्था"
        },
        {
          name: "2",
          label: "फाइनान्स"
        },
        {
          name: "3",
          label: "विकास वैंक"
        },
        {
          name: "4",
          label: "बैँक"
        },
        {
          name: "_",
          label: "अन्य"
        }
      ]
    },
    "begin_group_9275d410/fin_ownership": {
      type: "list",
      label: "प्रकार",
      children: [
        {
          name: "1",
          label: "सरकारी"
        },
        {
          name: "2",
          label: "निजी"
        },
        {
          name: "_",
          label: "अन्य"
        }
      ]
    }
  },
  poiType7: {
    "begin_group_4c142f27/religious_type": {
      type: "list",
      label: "धार्मिक स्थलको प्रकार",
      children: [
        {
          name: "1",
          label: "मन्दिर"
        },
        {
          name: "2",
          label: "मस्जीद"
        },
        {
          name: "3",
          label: "गुम्बा"
        },
        {
          name: "4",
          label: "चर्च"
        },
        {
          name: "others",
          label: "अन्य"
        }
      ]
    },
    "begin_group_4c142f27/religious_other": {
      type: "text",
      label: "अन्य खुलाउनुहोस"
    },
    "begin_group_4c142f27/bus_area": {
      type: "conversion",
      label: "क्षेत्रफल ( वर्ग किमि)"
    }
  },
  poiType8: {
    "begin_group_5c3cf8db/area": {
      type: "text",
      label: "क्षेत्रफल ( वर्ग किमि)"
    }
  },
  poiType9: {
    "begin_group_624246ab/org_type": {
      type: "list",
      label: "राष्ट्रिय तथा अन्तर्राष्ट्रिय गैरसरकारी संघसंस्थाकाे प्रकार",
      children: [
        {
          name: "1",
          label: "गैसस"
        },
        {
          name: "2",
          label: "अगैसस"
        },
        {
          name: "3",
          label:
            "समुदायमा आधारित संस्था (महिला समूह किसान समूह आमा समूह दलित संजाल आदि)"
        }
      ]
    },
    "begin_group_624246ab/cbo": {
      type: "text",
      label: "प्रकार समुदायमा आधारित भए कस्ता प्रकारके हेा लेख्नुहेास्"
    },
    "begin_group_624246ab/org_mission": {
      type: "text",
      label: "उद्देश्य/कार्य क्षेत्र"
    }
  },
  poiType10: {},
  poiType11: {}
};
