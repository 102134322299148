// import TableTop from "tabletop";
import axios from "axios";

export default {
  data() {
    return {
      map: {
        SET_DECISION: "Decision",
        SET_CITIZEN_CHARTER: "e-citizen-charter",
        SET_SECTOR_BUDGET: "important-sector-wise-fiscal-budget-allocation",
        SET_IMS_WARD_PROJECT: "IMS - ward wise project status",
        SET_POLICIES: "Important Policies",
        SET_ABOUT: "about",
        SET_ACHIEVEMENTS: "Achievements",
        SET_UPCOMING_PROGRAMS: "upcoming programs",
        SET_SELECTED_YEARLY_PROGRAMS: "selected yearly programs"
      },
      sheetID: "1FgVVZR0innzDZYOynubWg9dEwPI9aGNozpjAaWtt1VI",
      apikey: "AIzaSyBb3HTuhu-tl3ILqAJVd4UtSYhc6_HihU0",
      baseurl:
        "https://spreadsheets.google.com/feeds/list/1FgVVZR0innzDZYOynubWg9dEwPI9aGNozpjAaWtt1VI/"
    };
  },
  mounted() {
    this.$store.dispatch("setData", {
      mutation: "SET_LOADING",
      data: true
    });

    this.fetchSheetData("e-citizen-charter");
    this.fetchSheetData('Decision');
    this.fetchSheetData('important-sector-wise-fiscal-budget-allocation');
    this.fetchSheetData('IMS - ward wise project status');
    this.fetchSheetData('Important Policies');
    this.fetchSheetData('about');
    this.fetchSheetData('Achievements');
    this.fetchSheetData('upcoming programs');
    this.fetchSheetData('selected yearly programs');

    this.$store.dispatch("setData", {
      mutation: "SET_LOADING",
      data: false
    });
  },
  methods: {
    // fetchData(sheetNumber) {
    //   axios
    //     .get(this.baseurl + sheetNumber + "/public/values?alt=json")
    //     .then(({ data }) => {
    //       let sheetName = data.feed.title.$t;
    //       let entry = data.feed.entry;
    //       entry.shift();
    //       let filtered = [];

    //       entry.map(ele => {
    //         let tempObj = {};
    //         Object.keys(ele).map(key => {
    //           let firstFourLetters = key.substring(0, 4);
    //           if (firstFourLetters === "gsx$") {
    //             let param = key.substring(4);
    //             tempObj[param] = ele[key].$t;
    //           }
    //         });
    //         filtered.push(tempObj);
    //       });

    //       this.storeVuex(filtered, sheetName);
    //     })
    //     .catch(err => {
    //       // eslint-disable-next-line
    //       console.error(err);

    //       // this.fetchData(sheetNumber);
    //     });
    // },
    fetchSheetData(sheetName) {
      axios
        .get(
          `https://sheets.googleapis.com/v4/spreadsheets/${this.sheetID}/values/${sheetName}?alt=json&key=${this.apikey}`
        )
        .then(({ data }) => {
          let values = data.values;
          let header = values[0];
          values.splice(0, 2);
          let tmpArray = [];

          for (const key in values) {
            var tmpObject = {};
            for (const k in values[key]) {
              tmpObject[header[k].replace(/[#_↵]/g, "")] = values[key][k];
            }
            tmpArray.push(tmpObject);
          }
          this.storeVuex(tmpArray, sheetName);
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log(err);
        });
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    storeVuex(elements, sheetName) {
      let vuexMutation = this.getKeyByValue(this.map, sheetName) || null;

      if (vuexMutation) {
        this.$store.dispatch("setData", {
          mutation: vuexMutation,
          data: elements
        });
      }
    }
  }
};
