<template>
  <div class="main">
    <GlobalLoader :is-visible="this.$store.state.isLoading"></GlobalLoader>
    <Header></Header>
    <Sidebar></Sidebar>
    <main class="container">
      <router-view></router-view>
    </main>
    <!-- <footer class="footer">
      <unicon name="exclamation-circle"></unicon>मेल्लेख गाउँपालिकाका विभिन्न प्रणालीहरुमा रहेका आँकडालाई संग्रहित गरि यस प्रणालीमार्फत् प्रस्तुत गरिएकाे छ।
    </footer> -->
  </div>
</template>

<script>
import DataInitialize from "@/mixins/DataInitialize";
export default {
  mixins: [DataInitialize],
  components: {
    Sidebar: () => import("./views/shared/sidebar.vue"),
    Header: () => import("./views/shared/header.vue"),
    GlobalLoader: () => import("./components/GlobalLoader.vue")
  }
};
</script>

<style lang="scss" scoped>
.container {
  margin-right: 800px;

  @media screen and (max-width: 3839px) {
    margin-right: 670px;
  }
  
   @media screen and (max-width: 3199px) {
    margin-right: 320px;
  }

  @media screen and (max-width: 1800px) {
    margin-right: 285px;
  }
}
</style>
