import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import "@/scss/app.scss";

import {
  uniBookReader,
  uniLocationPinAlt,
  uniCoins,
  uniCommentAltInfo,
  uniMap,
  uniFileLandscapeAlt,
  uniFileAlt,
  uniExclamationCircle,
  uniSetting,
  uniUserSquare,
  uniSignOutAlt,
  uniFavorite,
  uniVenus,
  uniUser,
  uniWheelchair,
  uniUsersAlt,
  uniUserPlus,
  uniUserExclamation,
  uniClockThree,
  uniMoneyStack,
  uniBuilding,
  uniUniversity,
  uniEstate,
  uniPhoneAlt,
  uniCommentAltMessage,
  uniProcess,
  uniNotes,
  uniFileEditAlt,
  uniProcessMonochrome,
  uniCalendarAlt,
  uniArrowLeft,
  uniAngleUp,
  uniAngleDown
} from "vue-unicons/dist/icons";

Unicon.add([
  uniBookReader,
  uniLocationPinAlt,
  uniCoins,
  uniCommentAltInfo,
  uniMap,
  uniFileLandscapeAlt,
  uniFileAlt,
  uniExclamationCircle,
  uniSetting,
  uniUserSquare,
  uniSignOutAlt,
  uniFavorite,
  uniVenus,
  uniUser,
  uniWheelchair,
  uniUsersAlt,
  uniUserPlus,
  uniUserExclamation,
  uniClockThree,
  uniMoneyStack,
  uniBuilding,
  uniUniversity,
  uniEstate,
  uniPhoneAlt,
  uniCommentAltMessage,
  uniProcess,
  uniNotes,
  uniFileEditAlt,
  uniProcessMonochrome,
  uniCalendarAlt,
  uniArrowLeft,
  uniAngleUp,
  uniAngleDown
]);
Vue.use(Unicon);

Vue.config.productionTip = false;
//global registration of component
Vue.component('NoDataFound', () => import('./components/NoDataFound.vue'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
