import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    heading: "",
    fiscal_year: "2077-78",
    decisions: [],
    citizenCharter: [],
    sectorBudget: [],
    imsWardProject: [],
    policies: [],
    loadData: false,
    isLoading: false,
    about: [],
    imsWard: {},
    achievements: [],
    upcomingPrograms: [],
    selectedYearlyPrograms: []
  },
  getters: {
    heading(state) {
      return state.heading;
    },
    fiscal_year(state) {
      return state.fiscal_year;
    },
    decisions(state) {
      return state.decisions;
    },
    citizenCharter(state) {
      return state.citizenCharter;
    },
    sectorBudget(state) {
      return state.sectorBudget;
    },
    imsWardProjec(state) {
      return state.imsWardProjec;
    },
    policies(state) {
      return state.policies;
    },
    about(state) {
      return state.about;
    },
    imsWard(state) {
      return state.imsWard;
    },
    achievements(state) {
      return state.achievements;
    },
    upcomingPrograms(state) {
      return state.upcomingPrograms;
    },
    selectedYearlyPrograms(state) {
      return state.selectedYearlyPrograms;
    }
  },
  actions: {
    setData({ commit }, payload) {
      commit(payload.mutation, payload.data);
    },
    setFiscalYear({ commit }, payload) {
      commit("SET_FISCAL_YEAR", payload)
    },
    setAbout({ commit }, payload) {
      commit("SET_ABOUT", payload);
    },
    setImsWard({ commit }, payload) {
      commit("SET_IMS_WARD", payload)
    }
  },
  mutations: {
    SET_IMS_WARD(state, newValue) {
      state.imsWard = newValue;
    },
    SET_DECISION(state, newValue) {
      state.decisions = newValue;
    },
    SET_CITIZEN_CHARTER(state, newValue) {
      state.citizenCharter = newValue;
    },
    SET_SECTOR_BUDGET(state, newValue) {
      state.sectorBudget = newValue;
    },
    SET_IMS_WARD_PROJECT(state, newValue) {
      state.imsWardProject = newValue;
    },
    SET_POLICIES(state, newValue) {
      state.policies = newValue;
    },
    SET_HEADING(state, newValue) {
      state.heading = newValue;
    },
    SET_FISCAL_YEAR(state, newValue) {
      state.fiscal_year = newValue;
    },
    SET_LOADING(state, val) {
      state.isLoading = val
    },
    SET_ABOUT(state, newValue) {
      state.about = newValue;
    },
    SET_ACHIEVEMENTS(state, newValue) {
      state.achievements = newValue
    },
    SET_UPCOMING_PROGRAMS(state, newValue) {
      state.upcomingPrograms = newValue
    },
    SET_SELECTED_YEARLY_PROGRAMS(state, newValue) {
      state.selectedYearlyPrograms = newValue
    }
  }
});
